import { useEffect, useState } from "react";

import Input from "./Input";
import Button from "./Button";

import './Select.scss';

export default function Select(props) {

   // control value display
   const [value, setValue] = useState('');
   const [showValue, setShowValue] = useState('');
   const [showValueCache, setShowValueCache] = useState('');
   const [searchValue, setSearchValue] = useState('');

   // map values to shown values
   const [valueMap, setValueMap] = useState({});

   const [shown, setShown] = useState(false);
   const [focused, setFocused] = useState(false);
   const [showOptions, setShowOptions] = useState([]);

   const allowSearch = props.options && props.options.length > 3 && !props.noSearch;

   const handleFocus = (e) => {
      if (!shown) {
         
         setShown(true);
         setShowValueCache(showValue);

         // remove existing errors
         e.target.parentElement.getElementsByTagName('input')[0].classList.remove('error');
         if (e.target.parentElement.nextElementSibling && e.target.parentElement.nextElementSibling.classList.contains('error-message')) {
            e.target.parentElement.nextElementSibling.remove();
         }

         window.addEventListener('click', handleBlur);

         if (!props.options || props.readOnly) {
            return;
         }

         const query = searchValue;
         const newOptions = [];
         for (const option of props.options) {
            if (!allowSearch || query == "" || option.name.toLowerCase().includes(query.toLowerCase())) {
               newOptions.push(option);
            }
         }
         setShowOptions(newOptions);

         const multiSearchInput = document.getElementById(props.id + "-multi-search-options")
         if (multiSearchInput) {
            multiSearchInput.focus();
         }
         
      }
   }

   const handleBlur = (e) => {      

      if (!props.max && !props.readOnly) {
         const textInput = document.getElementById(props.id + "-show-value");
         if (!textInput.value.length) {
            setShowValue('');
            setShowValueCache('');
            setValue('');
         } else if (shown) {
            setShowValue(showValueCache);
         }
      }

      const select = document.getElementById(props.id);
      if (select && (!select.contains(e.target) || e.target.classList.contains('select-confirm'))) {
         window.removeEventListener('click', handleBlur);
         setShown(false);
      }

   }

   const handleSearchInput = (e) => {
      const query = e.target.value;
      setSearchValue(query);
      const newOptions = [];
      for (const option of props.options) {
         if (query == "" || option.name.toLowerCase().includes(query.toLowerCase())) {
            newOptions.push(option);
         }
      }
      setShowOptions(newOptions);
   }


   const selectValue = (e) => {

      const selectedValue = e.target.getAttribute('value');
      if (props.max) {
         
         let currentValues = value.length ? value.split(";") : [];
         if (currentValues.includes(selectedValue)) {
            currentValues.splice(currentValues.indexOf(selectedValue), 1);
         } else {
            if (currentValues.length >= props.max) {
               return;
            }
            currentValues.push(selectedValue);
         }

         setValue(currentValues.join(";").toLowerCase());
         setShowValue(currentValues.join(";"));
         if (props.onChange) props.onChange(e);

      } else {

         if (value != selectedValue) {
            setValue(selectedValue);
            setShowValue(valueMap[selectedValue]);
            setShowValueCache(valueMap[selectedValue]);
            if (props.onChange) props.onChange(e, selectedValue);
            window.removeEventListener('click', handleBlur);
            setShown(false);
            setSearchValue(valueMap[selectedValue].toLowerCase());
         } else {
            setValue('');
            setShowValue('');
            setShowValueCache('');
            if (props.onChange) props.onChange(e, '');
         }
         
      }

   }

   useEffect(() => {

      if (!props.noSort) {
         props.options.sort((a, b) => {

            // first, sort by category
            if (a.category && b.category) {
               if (a.category > b.category) {
                  return 1;
               } else if (a.category < b.category) {
                  return -1;
               }
            }

            // name
            if (a.name > b.name) {
               return 1;
            } else if (b.name < a.name) {
               return -1;
            }

            return 0;

         });
      }
      setShowOptions(props.options);

   }, [props.options]);

   useEffect(() => {
      console.log(props.readOnly);
      if (props.options && props.options.length && !value) {

         const newValueMap = {};
         for (const option of props.options) {
            newValueMap[option.value] = option.name;
         }
         setValueMap(newValueMap);

         // set initial value
         if (props.defaultValue) {    
            if (Array.isArray(props.defaultValue)) {
               const initValues = [];
               for (const val of props.defaultValue) {
                  for (const option of props.options) {
                     if (val == option.value) {
                        initValues.push(val);
                     }
                  }
               }
               setValue(initValues.join(';'));
            } else if (props.defaultValue) {
               setValue(props.defaultValue);
            }
         }

         if (!props.max && newValueMap[props.defaultValue]) {
            setSearchValue(newValueMap[props.defaultValue].toLowerCase());
            setShowValue(newValueMap[props.defaultValue]);
         }

      }

   }, [props.options, props.defaultValue]);

   return (
      
      <>
         
         <div 
            id={props.id}
            className={"select" + (shown ? " show" : "") + (props.random ? " random" : "") + (props.icon ? " with-icon" : "") + (props.readOnly ? " readonly" : "") + (props.max ? " multi" : "") + (props.className ? " " + props.className : "")}
            onBlur={handleBlur} 
            style={{zIndex: (shown ? 99999: null)}}>
            
            {props.icon &&
               <span className="icon">
                  {props.icon}
               </span>
            }
            
            <input 
               id={props.id + "-value"}
               type="hidden"
               name={props.name}
               value={value}
               required={props.required} />

            <input 
               id={props.id + "-show-value"}
               className={"select-search" + (value && value.length > 0 ? " not-empty" : "")}
               type="text"
               autoComplete="off"
               value={props.max ? ' ' : showValue}
               onFocus={handleFocus}
               onKeyDown={(e) => { if (e.key == 'Tab') setShown(false) }}
               readOnly />

            <span className="placeholder">
               {props.placeholder}
               {props.required && <span className="required">*</span>}
            </span>

            {props.max && value &&
               <div className="selected-values">
                  <>{value.split(";").slice(0, 3).map((val, key) => {

                     if (key == 2) {
                        return (<span className="selected-value too-many" key={key}>+{value.split(";").length - 2}...</span>)
                     }

                     const displayValue = valueMap[val].length > 10 ? valueMap[val].substring(0, 8) + '...' : valueMap[val];

                     return (
                        <span className="selected-value" key={key}>{displayValue}</span>
                     )
                  })}</>
               </div>
            }

            <div className="options">
               
               {allowSearch && 

                  <Input 
                     id={props.id + "-multi-search-options"}
                     className="options-search"
                     onInput={handleSearchInput}
                     onKeyDown={(e) => { if (e.key == 'Tab') setShown(false) }}
                     defaultValue={searchValue}
                     autoComplete="off"
                     icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"/></svg>}
                     type="text" 
                     />

               }

               <ul
                  className={
                     (value && value.split(";").length >= props.max ? "max-selected" : "") +
                     (allowSearch ? " allow-search" : "")
                  }
                  tabIndex="-1"
                  >

                  {showOptions.map((option, key) => {

                     let header = <></>
                     if (option.category && (key == 0 || option.category != showOptions[key - 1].category)) {
                        header = <h4 key={-1}>{option.category}</h4>
                     }

                     return (
                        <>
                           {header}
                           <li 
                              key={option.value} 
                              onClick={selectValue} 
                              value={option.value}
                              className={value == option.value.toLowerCase() || (props.max && value.includes(option.value.toLowerCase())) ? "selected" : ""}>
                                 {option.name} 
                                 {option.count !== null && option.count !== undefined && 
                                    <span className="count">{option.count}</span>
                                 }
                              {props.max && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>}
                           </li>
                        </>
                     );
                  })}
                  {showOptions.length == 0 && 
                     <li className="inactive">Geen overeenkomsten</li>}
               </ul>

               <Button
                  className="select-confirm"
                  name="Bevestig keuze"
                  onClick={handleBlur}
                  noLink
                  cta
                  />

            </div>

            <svg 
               className="chevron"
               onClick={() => { if (shown) setShown(false)}}
               xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>

         </div>

      </>

   );

}