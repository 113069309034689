import { useEffect, useState } from "react";

import Form from "../components/inputs/Form";
import Input from "../components/inputs/Input";
import { useAuth } from "../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router";

import './WelcomePage.scss';

export default function WelcomePage() {

   const [type, setType] = useState('user');
   
   const navigate = useNavigate();
   const { user } = useAuth();

   useEffect(() => {
      setType(user.type);
   }, [user.type]);

   return (

      <div id="welcome">

         <section id="hero">
            
            <div className="container">

               <div id="hero-tagline">
                  <h1>Hartelijk welkom!</h1>
                  <p>{(
                     type == 'user' ?
                     "Begin hier met de eerste stap naar een verbeterd welzijn." :
                     "Begin met de eerste stap naar meer cliënten voor jouw welzijnsactiviteit."
                  )}</p>
               </div>

               <div id="hero-image">
                  <img src={require('../assets/welcome.jpg')} />
               </div>

            </div>

         </section>         

         <section id="info" className="container">

            <div className="row">
               <div className="text">
                  <h2>{type == 'user' ? 'Het aanbod' : 'De doelgroep'}<span className="stress">.</span></h2>
                  <p>{(
                     type == 'user' ?
                     "Op You Be Well vind je een breed aanbod van diensten van lokale aanbieders, zodat jij op jouw manier kunt werken aan een gelukkiger en gezonder leven." :
                     "Onze doelgroep bestaat uit gebruikers die via een werkgeversprogramma of zelf actief met hun well-being bezig zijn. De ideale doelgroep voor jouw activiteit."
                  )}</p>
               </div>
               <div className="image">
                  <img src={require('../assets/woman_phone.jpg')} />
               </div>
            </div>

            <div className="row">
               <div className="text">
                  <h2>Contact met {type == 'user' ? 'aanbieders' : 'cliënten'}<span className="stress">.</span></h2>
                  <p>{(
                     type == 'user' ?
                     "Na het maken van een boeking kun je via de chat in contact komen met de aanbieder, zodat de planning en details van de activiteit volledig op jouw wensen kunnen worden afgestemd." :
                     "Beheer je profielen eenvoudig zelf via ons platform, zodat informatie altijd actueel blijft. Verder krijg je via een chat contact na een boeking: zo past elke boeking naadloos in je planning."
                  )}</p>
               </div>
               <div className="image">
                  <img src={require('../assets/client_meeting.jpg')} />
               </div>
            </div>

            <div className="row highlight">
               <div className="text">
                  <h2>{type === "user" ? "Budget opwaarderen" : "Spread the word."}<span className="stress">.</span></h2>
                  <p>{(
                     type === "user" ?
                     "Is het budget van jouw werkgever op of wil je extra besteden? Binnenkort kun je eenvoudig je budget opwaarderen via Tikkie. Zo kun je blijven investeren in jouw welzijn, wanneer jij dat wilt. " :
                     "Ken jij organisaties die baat hebben bij You Be Well? Laat het ons weten! Via ons referral programma ontvang je een mooie bonus als jouw introductie leidt tot een samenwerking."
                  )}
                  </p>
               </div>
               <div className="image">
                  <img src={require('../assets/entrepreneur.jpg')} />
               </div>
            </div>

         </section>

         <section id="welcome-finish">

            <div id="welcome-finish-waves">

               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,128L120,160C240,192,480,256,720,234.7C960,213,1200,107,1320,53.3L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,128L120,160C240,192,480,256,720,234.7C960,213,1200,107,1320,53.3L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>

            </div>
                     
            <div className="container">
               
               <div className="form-container">

                  <h3>Tijd om te beginnen.</h3>
                  <p>Vul de laatste informatie in en je kunt van start.</p>

                  <Form
                     action="/api/v1/user"
                     submitBtnText="Opslaan"
                     submitCallback={() => {
                        user.first_name = document.getElementById('welcome-first-name').value;
                        user.last_name = document.getElementById('welcome-last-name').value;
                        navigate('/hub/profile');
                     }}
                     >
                     
                     <Input 
                        id="welcome-first-name"
                        placeholder="Je voornaam"
                        example="Bijv. Noah"
                        name="first_name"
                        type="text"
                        maxLength="50"
                        required
                        />

                     <Input 
                        id="welcome-last-name"
                        placeholder="Je achternaam"
                        example="Bijv. Van den Berg"
                        name="last_name"
                        type="text"
                        maxLength="50"
                        required
                        />

                  </Form>

               </div>

            </div>

         </section>

      </div>

   );

}